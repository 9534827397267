<template>
    <div class="banners" v-if="info">
        <div class="banners-wrap" :style="{ 'background-image': `url('${info.image}')` }" :attr="`${info.image}`">
            <div class="banners-text">
                <div class="banners-title">{{ info.title }}</div>
                <div class="banners-description" v-html="info.description"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "banner",
    props: {
        info: {
            type: Object,
            default: () => {},
        },
    },
};
</script>

<style scoped lang="scss">
.banners {
    margin-bottom: 30px;

    &-wrap {
        height: 500px;
        background-position: center;

        //position: relative;
        width: 100%;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            z-index: -1;
        }
    }

    &-text {
        padding: 100px 0 0 139px;
    }

    &-title {
        font-weight: normal;
        font-size: 40px;
        line-height: 49px;
        color: #000000;
        margin-bottom: 25px;
    }

    &-description {
        font-weight: normal;
        font-size: 60px;
        line-height: 80px;
        text-transform: uppercase;
        color: #000000;
    }
}

@media screen and (max-width: 1600px) {
    .banners-description {
        font-size: 40px;
        line-height: 70px;
    }
}

@media screen and (max-width: 1280px) {
    .banners {
        &-text {
            left: 30px;
        }

        &-title {
            font-size: 24px;
            line-height: 1.2;
        }

        &-description {
            font-size: 32px;
            line-height: 1.2;
        }
    }
}

@media screen and (max-width: 900px) {
    .banners-text {
        padding: 70px 0 0 100px;
    }
    .banners-wrap {
        height: 400px;
    }
}

@media screen and (max-width: 760px) {
    .banners {
        &-text {
            left: 30px;
        }

        &-title {
            font-size: 18px;
            line-height: 1.2;
        }

        &-description {
            font-size: 26px;
            line-height: 1.2;
        }
    }
}

@media screen and (max-width: 600px) {
    .banners-text {
        padding: 30px 0 0 30px;
    }
    .banners-wrap {
        height: 300px;
    }
}
</style>
