<template>
    <div class="patent">
        <div class="patent-wrap" :class="{ imageRight: imageRight }">
            <div class="patent-img">
                <img :src="info.file ? info.file : info.image" alt="patent-file" />
            </div>
            <div class="patent-text">
                <div class="patent-text__title">{{ info.title }}</div>
                <div class="patent-text__description" v-html="info.description"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "patent",
    props: {
        info: {
            type: Object,
            default: () => {},
        },
        imageRight: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style scoped lang="scss">
.imageRight {
    flex-direction: row-reverse;
}

.patent {
    &-wrap {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    &-img {
        width: 50%;
        height: 630px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &-text {
        margin-left: -50px;
        width: 55%;
        background: #f2f2f2;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 30px 50px;

        &__title {
            font-weight: normal;
            font-size: 40px;
            line-height: 49px;
            text-transform: uppercase;
            color: #000000;
        }

        &__description {
            margin-top: 20px;
            font-weight: normal;
            font-size: 18px;
            line-height: 24px;
            color: #4f4f4f;

            ul {
                list-style: disc !important;
            }

            ol {
                list-style: decimal;
            }
        }
    }
}

@media screen and (max-width: 850px) {
    .patent-text__title {
        font-size: 30px;
        line-height: 39px;
    }
    .patent-text__description {
        font-size: 16px;
        line-height: 20px;
    }
}

@media screen and (max-width: 750px) {
    .patent-img {
        height: 400px;
    }
    .patent-text {
        padding: 30px;
        height: 350px;
    }
    .patent-text__title {
        font-size: 24px;
        line-height: 26px;
    }
    .patent-text__description {
        font-size: 16px;
        line-height: 18px;
        margin-top: 10px;
    }
}

@media screen and (max-width: 650px) {
    .patent-wrap {
        flex-direction: column;
    }
    .patent-text {
        margin-left: 0;
        width: 100%;
        height: auto;
    }
    .patent-img {
        width: 100%;
    }
}

@media screen and (max-width: 400px) {
    .patent-text {
        //height: 400px;
        margin-left: 0;
        padding: 15px;
    }
}
</style>
