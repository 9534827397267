<template>
    <div class="phrase" v-if="info.type === 'phrase'">
        <div class="phrase-text" v-html="info.description"></div>
    </div>
</template>

<script>
export default {
    name: "index",
    props: {
        info: {
            type: Object,
            default: () => {},
        },
    },
};
</script>

<style scoped lang="scss">
.phrase {
    padding-left: 40px;
    padding-top: 25px;
    padding-bottom: 25px;
    border-top: 1px solid #f2f2f2;
    border-bottom: 1px solid #f2f2f2;

    &-text {
        font-weight: normal;
        font-size: 18px;
        line-height: 22px;
        color: #4f4f4f;
    }
}

@media screen and (max-width: 950px) {
    .TableNormal {
        display: none;
        margin-right: 0;
        width: 100% !important;
        overflow-y: scroll;
    }
    .phrase {
        padding-left: 0;

        &-text {
            .TableNormal {
                display: none;
                margin-right: 0;
                width: 100% !important;
                overflow-y: scroll;
            }
        }
    }
}
</style>
