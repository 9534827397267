import { mapGetters, mapMutations } from "vuex";
import { PopupName } from "@/store/modules/popups";

export default {
    name: "metodistBlock",
    data() {
        return {
            playVideo: false,
        };
    },
    props: {
        info: {},
    },
    computed: {
        ...mapGetters({
            metodistVideo: "popups/currentModalPayload",
            currentModal: "popups/currentModal",
        }),
        metodistPopup() {
            return this.currentModal === PopupName.metodistPopup
        },
    },
    methods: {
        ...mapMutations({
            changeMetodistPopup: "popups/CHANGE_METODIST_POPUP",
        }),
        handleOverlayClick(video) {
            this.changeMetodistPopup({status: true, payload: video});
        },
        handleVideoEnd() {
            this.playVideo = false;
        },
    },
};
