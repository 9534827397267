<template>
    <div class="page">
        <div class="container">
            <div class="page-wrap" v-if="page">
                <div class="page-title" v-if="showPageTitle()"></div>
                <div class="page-title__second" :class="{ big: isBigSecondTitle() }">
                    {{ page.title }}
                </div>

                <div v-if="pageSectionBlocks.length">
                    <static-content>
                        <template v-if="page.configType === 'our-methodists'">
                            <div class="our-metodist--wrap">
                                <template v-for="block in pageSectionBlocks">
                                    <metodistBlock :info="block" @video-play="handleVideoPlay" />
                                </template>
                            </div>
                        </template>
                        <template v-else>
                            <div v-for="block in pageSectionBlocks" :key="block.id">
                                <columnBlock :list="block.items" v-if="block.items" class="scrolled" />
                                <imageLeft v-if="block.type === 'image_left'" :info="block" style="margin-bottom: 24px" class="scrolled" />
                                <imageLeft v-if="block.type === 'image_right'" :info="block" :imageRight="true" style="margin-bottom: 24px" class="scrolled" />
                                <block-with-video v-if="block.type === 'block_with_video'" :info="block" class="scrolled" />
                                <title-with-description v-if="block.type === 'title_with_description'" :info="block" class="scrolled" />
                                <title-with-description v-if="block.type === 'title_with_center_description'" :positionCenter="true" :info="block" class="scrolled" />
                                <phrase v-if="block.type === 'phrase'" :info="block" class="scrolled" />
                                <block-with-image v-if="block.type === 'block_with_image'" :info="block" class="scrolled" />
                                <mapGeolocation v-if="block.type === 'map'" :info="block" class="scrolled" />
                                <description v-if="block.type === 'description'" :info="block" class="scrolled" />
                                <block-with-image-with-button v-if="block.type === 'block_with_image_with_button'" :info="block" class="scrolled" />
                                <banner v-if="block.type === 'banner'" :info="block" class="scrolled" />
                            </div>
                        </template>
                    </static-content>
                </div>

                <div class="education" v-if="page.configType === 'online-education' && webinarCourseThemes.length">
                    <div class="education-wrap">
                        <p class="education-sub_title">{{ $t("selectASection") }}:</p>
                        <ul class="education__head" :class="{ withImage: page.image }">
                            <li v-for="courseTheme in webinarCourseThemes" :key="courseTheme.id">
                                <router-link
                                    :to="
                                        $localize({
                                            name: 'sub-page',
                                            params: { slugPage: 'platform', slugIngredient: courseTheme.id },
                                            query: { type: 'webinar' },
                                        })
                                    "
                                >
                                    <div class="education__head-icon">
                                        <img :src="courseTheme.image" :alt="courseTheme.title" />
                                    </div>
                                    <div class="education__head-desc">
                                        <h2 v-html="courseTheme.title"></h2>
                                        <p v-html="courseTheme.description"></p>
                                    </div>
                                </router-link>
                            </li>
                        </ul>
                        <div class="education__head-img" v-if="page.image">
                            <img :src="page.image" :alt="page.title" />
                        </div>
                    </div>

                    <div class="education__patent" v-if="page.seminarBanner">
                        <div class="education__patent-wrap" :class="{ imageRight: 'imageRight' }">
                            <div class="education__patent-img">
                                <img :src="page.seminarBanner.image" alt="patent-file" />
                            </div>
                            <div class="education__patent-text">
                                <div class="education__patent-text__title">{{ page.seminarBanner.title }}</div>
                                <div class="education__patent-text__description" v-html="page.seminarBanner.description"></div>
                                <div class="education__patent-text__button">
                                    <a :href="page.seminarBanner.buttonLink" class="main-button">
                                        <span class="main-button__label">
                                            {{ page.seminarBanner.buttonText }}
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="partners" v-if="page.partners">
                    <div class="page-partner" v-for="(partner, index) in page.partners.data" :key="index">
                        <partner :info="partner" />
                    </div>
                </div>

                <template v-if="page.qualityCertificates">
                    <div class="certificate-list">
                        <div class="page-certificate" v-for="certificate in certificatesList" :key="certificate.id">
                            <certificate :info="certificate" />
                        </div>
                    </div>
                    <div class="certificate-load">
                        <main-button :outline="true" v-show="page.qualityCertificates.data.length !== certificatesList.length" :progress="loadingMoreContent" @change-event="loadMoreCertificates(3)" :label="$t('loadMore')"></main-button>
                    </div>
                </template>

                <template v-if="page.patents">
                    <div class="patents">
                        <div class="page-patent" v-for="patent in patentsList" :key="patent.id">
                            <patent :info="patent" />
                        </div>
                    </div>
                    <div class="patent-load">
                        <main-button :outline="true" v-show="page.patents.data.length !== patentsList.length" :progress="loadingMoreContent" @change-event="loadMorePatents(3)" :label="$t('loadMore')"></main-button>
                    </div>
                </template>

                <div v-if="page.videoPresentations">
                    <div v-for="(item, index) in page.videoPresentations.data" :key="index" class="presentations">
                        <iframe width="100%" :src="'https://www.youtube.com/embed/' + item.videoId" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        <div class="presentations__content">
                            <h2 v-html="item.title"></h2>
                            <div class="presentations__content_description" v-html="item.description"></div>
                        </div>
                    </div>
                </div>

                <template v-if="page.innovativeIngredients">
                    <div class="innovatives">
                        <!--todo - route title for breadcrumbs-->
                        <router-link
                            :to="
                                $localize({
                                    name: 'sub-page',
                                    params: {
                                        slugPage: page.slug,
                                        slugIngredient: ingredient.id,
                                    },
                                    query: { title: $route.query.title, type: 'ingredient' },
                                })
                            "
                            class="page-innovative"
                            v-for="ingredient in ingredientsList"
                            :key="ingredient.id"
                        >
                            <ingredients :info="ingredient" />
                        </router-link>
                    </div>
                    <div class="innovatives-load">
                        <main-button :outline="true" v-show="page.innovativeIngredients.data.length !== ingredientsList.length" :progress="loadingMoreContent" @change-event="loadMoreIngredients(3)" :label="$t('loadMore')"></main-button>
                    </div>
                </template>

                <template v-if="page.configType === 'scientific-news'">
                    <div class="news">
                        <div class="page-new" v-for="(item, index) in newsList" :key="item.id">
                            <static-content>
                                <one-new :info="item" :oposit="index % 2 === 0" />
                            </static-content>
                        </div>
                    </div>
                    <div class="news-load">
                        <main-button :outline="true" v-show="newsPaginator.hasNextPage" :progress="loadingMoreContent" @change-event="loadMoreNews()" :label="$t('loadMore')"></main-button>
                    </div>
                </template>

                <div class="before-after" v-if="page.beforeAfters">
                    <div class="page-before__after" v-for="item in page.beforeAfters.data" :key="item.id">
                        <before-after :info="item" />
                    </div>
                </div>

                <div class="protocols" v-if="page.catalogAndProtocols">
                    <div class="page-protocol" v-for="item in page.catalogAndProtocols.data" :key="item.id">
                        <protocol :info="item" />
                    </div>
                </div>

                <div class="terms" v-if="page.configType === 'terms-of-cooperation'">
                    <div class="page-term" v-for="(item, index) in cooperationTerms" :key="item.id">
                        <terms :info="item" :oposit="index % 2 !== 0" />
                    </div>
                </div>

                <div class="page-button" v-if="page.configType === 'application-for-presentation'">
                    <main-button :label="$t('sendRequest')" @change-event="changeApplicationFormPopup(true)" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { take } from "lodash";

import blockWithImage from "../components/block-with-image/index.vue";
import titleWithDescription from "../components/title-with-description/index.vue";
import phrase from "../components/phrase/index.vue";
import blockWithVideo from "../components/block-with-video/index.vue";
import mapGeolocation from "../components/map/index.vue";
import certificate from "../components/certificate/index.vue";
import patent from "../components/patent/index.vue";
import ingredients from "../components/ingredient/index.vue";
import oneNew from "../components/new/index.vue";
import beforeAfter from "../components/before-after/index.vue";
import protocol from "../components/protocol/index.vue";
import terms from "../components/terms/index.vue";
import description from "../components/description/index.vue";
import partner from "../components/partner/index.vue";
import blockWithImageWithButton from "../components/block-with-image-with-button/index.vue";
import banner from "../components/banner/index.vue";
import staticContent from "@/components/static-content/index.vue";
import imageLeft from "@/modules/pages/components/image-left";
import columnBlock from "@/modules/pages/components/column-block/index.vue";
import metodistBlock from "./component/index.vue";

import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
    name: "page-wrap",
    components: {
        banner,
        blockWithImage,
        titleWithDescription,
        phrase,
        blockWithVideo,
        mapGeolocation,
        certificate,
        patent,
        ingredients,
        oneNew,
        beforeAfter,
        protocol,
        terms,
        description,
        partner,
        blockWithImageWithButton,
        staticContent,
        imageLeft,
        columnBlock,
        metodistBlock,
    },
    data() {
        return {
            loadingMoreContent: false,
            currentlyPlaying: null,

            ingredientSkip: 4,
            certificatesSkip: 4,
            patentsSkip: 4,

            cooperationTerms: [],
            webinarCourseThemes: [],

            newsList: [],
            newsPaginator: {
                hasNextPage: false,
                page: 1,
                perPage: 3,
            },
        };
    },
    computed: {
        ...mapGetters({
            page: "pages/page",
            pageSectionBlocks: "pages/pageSectionBlocks",

            headerMenu: "setting/headerMenu",
        }),
        ingredientsList() {
            return take(this.page.innovativeIngredients?.data || [], this.ingredientSkip);
        },
        certificatesList() {
            return take(this.page.qualityCertificates?.data || [], this.certificatesSkip);
        },
        patentsList() {
            return take(this.page.patents?.data || [], this.patentsSkip);
        },
    },
    watch: {
        "newsPaginator.page"() {
            this.fetchNewsForPage();
        },
    },
    async created() {
        await this.fetchContentForPage();
        await this.fetchContent();
    },
    methods: {
        ...mapActions({
            fetchPage: "pages/GET_PAGE",
            fetchNewsPage: "pages/GET_NEWS_PAGE",
            fetchTerm: "pages/GET_ONE_TERM",

            fetchNews: "pages/GET_NEWS",
            fetchCooperationTerms: "pages/COOPERATION_TERMS",
            fetchCourseThemes: "pages/WEBINAR_COURSE_THEMES",
        }),
        ...mapMutations({
            changeApplicationFormPopup: "popups/CHANGE_APPLICATION_FORM_POPUP",
        }),
        handleVideoPlay(metodistBlock) {
            if (this.currentlyPlaying && this.currentlyPlaying !== metodistBlock) {
                this.currentlyPlaying.$refs.videoRef.pause();
                this.currentlyPlaying.playVideo = false;
            }

            this.currentlyPlaying = metodistBlock;
        },
        async fetchContentForPage() {
            if (!this.$route.query.type) {
                await this.fetchPage(this.$route.params.slug);
            } else if ("terms" === this.$route.query.type) {
                await this.fetchTerm(this.$route.params.slug);
            } else if ("new" === this.$route.query.type) {
                await this.fetchNewsPage(this.$route.params.slug);
            }
        },
        async fetchContent() {
            if ("scientific-news" === this.page.configType) {
                this.newsList = [];
                await this.fetchNewsForPage();
            } else if ("terms-of-cooperation" === this.page.configType) {
                this.cooperationTerms = await this.fetchCooperationTerms();
            } else if ("online-education" === this.page.configType) {
                this.webinarCourseThemes = await this.fetchCourseThemes();
            }
        },
        async fetchNewsForPage() {
            this.loadingMoreContent = true;

            const newsData = await this.fetchNews({
                page: this.newsPaginator.page,
                perPage: this.newsPaginator.perPage,
            });

            this.newsList.push(...newsData.data);

            this.newsPaginator.hasNextPage = !!newsData.links.next;

            this.loadingMoreContent = false;
        },
        isBigSecondTitle() {
            const pageConfigTypesForTitle = ["catalog-and-protocols", "application-for-presentation", "before-after", "terms-of-cooperation", "scientific-news", "patents", "innovative-ingredients", "partners"];

            return this.$route.query.smallTitle || pageConfigTypesForTitle.includes(this.page.configType);
        },
        showPageTitle() {
            return !this.isBigSecondTitle();
        },
        loadMoreIngredients(skip) {
            this.ingredientSkip += skip;
        },
        loadMoreCertificates(skip) {
            this.certificatesSkip += skip;
        },
        loadMorePatents(skip) {
            this.patentsSkip += skip;
        },
        loadMoreNews() {
            this.newsPaginator.page += 1;
        },
    },
};
</script>

<style scoped lang="scss">
.page {
    .education {
        .withImage {
            width: calc(100% - 470px);
        }

        &-wrap {
            margin: 40px 0 50px;
            padding-bottom: 10px;
        }

        &-sub_title {
            margin-bottom: 10px;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            color: #000000;
        }

        &__head {
            font-weight: normal;
            font-size: 14px;
            line-height: 17px;
            color: #828282;
            list-style: none;

            &-img {
                max-width: 450px;
                width: 100%;
                height: 700px;
                position: absolute;
                right: 15px;
                top: -77px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            li + li {
                margin-top: 20px;
            }

            li {
                a {
                    display: flex;
                    align-items: flex-start;
                    justify-content: space-between;
                    text-decoration: none;

                    .education__head-icon {
                        width: 100px;
                        height: 100px;
                        border-radius: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        svg,
                        img {
                            transition: ease-in-out 0.3s;
                            width: 80%;
                        }
                    }

                    .education__head-desc {
                        width: calc(100% - 130px);

                        h2,
                        p {
                            margin: 0;
                        }

                        h2 {
                            display: inline-block;
                            font-weight: 500;
                            font-size: 20px;
                            color: #000000;
                            margin-bottom: 5px;
                            border-bottom: 1px solid transparent;
                            transition: ease-in-out 0.3s;
                            text-transform: uppercase;
                        }

                        p {
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 18px;
                            color: #000000;
                        }
                    }

                    &:hover {
                        .platform__head-icon {
                            img,
                            svg {
                                transform: scale(1.05);
                                transition: ease-in-out 0.3s;
                            }
                        }

                        .platform__head-desc {
                            h2 {
                                border-color: black;
                                transition: ease-in-out 0.3s;
                            }
                        }
                    }
                }
            }
        }

        &__patent {
            &-wrap {
                display: flex;
                align-items: center;
                justify-content: flex-start;
            }

            &-img {
                width: 50%;
                height: 630px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            &-text {
                margin-left: -50px;
                width: 55%;
                background: #f2f2f2;
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding: 30px 50px;

                .main-button {
                    text-decoration: none;
                }

                &__button {
                    max-width: 250px;
                    margin: auto;
                    margin-top: 20px;
                }

                &__title {
                    font-weight: normal;
                    font-size: 40px;
                    line-height: 49px;
                    text-transform: uppercase;
                    color: #000000;
                }

                &__description {
                    margin-top: 20px;
                    font-weight: normal;
                    font-size: 18px;
                    line-height: 24px;
                    color: #4f4f4f;

                    ul {
                        list-style: disc !important;
                    }

                    ol {
                        list-style: decimal;
                    }
                }
            }
        }
    }

    .our-metodist {
        &--wrap {
            margin-top: 40px;
            display: grid;
            gap: 32px;
            grid-template-columns: 1fr 1fr 1fr;
        }
    }

    .column_block-wrapper {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    ul {
        padding: 20px 0 20px 20px;
    }

    ol {
        padding: 20px 0 20px 20px;
    }

    .presentations {
        display: flex;
        justify-content: space-between;
        margin-bottom: 40px;
        align-items: center;

        iframe {
            width: 60%;
            height: 450px;
        }

        &__content {
            width: 43%;
            background: #f2f2f2;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 30px 50px;
            height: fit-content;

            h2 {
                font-size: 24px;
                line-height: 32px;
                text-transform: uppercase;
                color: #000000;
                margin-bottom: 0;
            }

            &_description {
                margin-top: 20px;
                font-weight: normal;
                font-size: 18px;
                line-height: 24px;
                color: #4f4f4f;
            }
        }
    }

    .certificate-list {
        display: flex;
        flex-wrap: wrap;
        margin-left: -60px;
        margin-right: -60px;
        //margin-top: -10px;
    }

    &-title {
        font-weight: normal;
        font-size: 40px;
        line-height: 49px;
        text-transform: uppercase;
        color: #000000;
        margin-top: 25px;

        &__second {
            font-weight: normal;
            font-size: 40px;
            line-height: 40px;
            color: #4f4f4f;
            margin-bottom: 10px;

            &.big {
                font-weight: normal;
                font-size: 40px;
                line-height: 49px;
                text-transform: uppercase;
                color: #000000;
            }
        }
    }

    .certificate,
    .patent,
    .news {
        &-load {
            display: flex;
            justify-content: center;
            margin-top: 55px;

            button {
                width: 200px;
                height: 50px;
            }
        }
    }

    &-certificate {
        width: calc(50% - 120px);
        margin: 0px 60px;
    }

    &-patent {
        margin-bottom: 55px;
    }

    .innovatives {
        width: 100%;
        margin: 0 auto;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 10px;
        grid-auto-rows: 100px;
        grid-auto-flow: dense;

        &-load {
            display: flex;
            justify-content: center;
            margin-top: 55px;

            button {
                width: 200px;
                height: 50px;

                .loader {
                    min-height: unset;
                }
            }
        }
    }

    &-new {
        margin-bottom: 55px;
    }

    &-innovative {
        background-color: grey;
        grid-row: span 2;
        cursor: pointer;

        &:nth-child(5n - 4) {
            grid-row: span 4;
        }

        &:nth-child(5n - 3) {
            grid-row: span 3;
        }

        &:nth-child(5n - 2) {
            grid-row: span 3;
        }

        &:nth-child(5n - 1) {
            grid-row: span 4;
        }

        &:nth-child(5n) {
            grid-row: span 3;
        }
    }

    &-before__after {
        margin-bottom: 100px;
    }

    &-button {
        display: flex;
        justify-content: center;
        height: 43px;
        margin: auto;

        button {
            max-width: 250px;
        }

        max-width: 1050px;
        width: 100%;
    }

    &-term {
        margin-bottom: 55px;
    }

    .protocols {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin-left: -40px;
        margin-right: -40px;
        justify-content: flex-start;
    }

    &-protocol {
        margin: 45px;
        margin-top: 0;
        width: calc(25% - 90px);
    }
}

@media screen and (max-width: 1280px) {
    //.page .container {
    //  max-width: 1050px;
    //  width: 100%;
    //}
    .static-content {
        .our-metodist--wrap {
            grid-template-columns: 1fr 1fr;
        }
    }
    .page-protocol {
        width: calc(30% - 45px);
        margin: 39px;
        margin-top: 0;
    }
}

@media screen and (max-width: 1140px) {
    .page-protocol {
        margin-top: 0;
        width: calc(45% - 45px);
        margin: 40px;
    }
    .page-certificate {
        width: 100%;
    }
    .page-title,
    .page-title__second.big {
        font-size: 32px;
    }
    .page-title__second {
        font-size: 22px;
        margin-top: 15px;
        margin-bottom: 30px;
    }
}

@media screen and (max-width: 980px) {
    .page .education .withImage {
        width: 100%;
    }
    .page .education__head-img {
        display: none;
    }
    .presentations {
        flex-direction: column;
    }
    .page .presentations iframe {
        width: 100%;
        height: 500px;
        margin-bottom: 30px;
    }
    .page .presentations__content {
        width: 100%;
        margin-left: 0;
    }
}

@media screen and (max-width: 850px) {
    .static-content {
        .our-metodist--wrap {
            margin-top: 0px;
            grid-template-columns: 1fr;
        }
    }
    .page .education__patent-text__title {
        font-size: 30px;
        line-height: 39px;
    }
    .page .education__patent-text__description {
        font-size: 16px;
        line-height: 20px;
    }
}

@media screen and (max-width: 750px) {
    //.page .education__patent-img img {
    //  height: 400px;
    //}
    .patent-text {
        padding: 30px;
        height: 350px;
    }
    .page .education__patent-text__title {
        font-size: 24px;
        line-height: 26px;
    }
    .page .education__patent-text__description {
        font-size: 16px;
        line-height: 18px;
        margin-top: 10px;
    }
}

@media screen and (max-width: 650px) {
    .page .education__patent-img {
        width: 100%;
        height: 400px;
    }
    .page .education__patent-wrap {
        flex-direction: column;
    }
    .page .education__patent-text {
        margin-left: 0;
        width: 100%;
        height: auto;
        padding: 30px;
    }
    .page .education__patent-img img {
        width: 100%;
    }
    .page .education__head-img {
        max-width: 100%;
        height: 550px;
        margin-bottom: 50px;
    }
}

@media screen and (max-width: 400px) {
    .patent-text {
        //height: 400px;
        margin-left: 0;
        padding: 15px;
    }
}

@media screen and (max-width: 900px) {
    .page-protocol {
        width: calc(100% - 45px);
        margin: 40px;
    }
    .page .presentations iframe {
        height: 400px;
    }
}

@media screen and (max-width: 720px) {
    .page .presentations iframe {
        height: 350px;
    }

    .page-certificate {
        margin: 0;
    }

    .certificate-list {
        margin-left: 0 !important;
        margin-right: 0 !important;

        .certificate-image, .certificate-wrap {
            width: 100%;
        }
    }
}

@media screen and (max-width: 660px) {
    .page .education__patent-text__title[data-v-5c12baef] {
        font-size: 30px;
    }
    .page .presentations__content {
        padding: 24px 25px;
    }
    .page .presentations__content h2 {
        font-size: 24px;
        line-height: 26px;
    }
    .presentations__content_description {
        font-size: 16px;
        line-height: 18px;
        margin-top: 10px;
    }
    .page .presentations iframe {
        height: 250px;
    }
    .protocol-download {
        bottom: 20%;
        right: -40px;
    }
    .page-protocol {
        width: 100%;
        //margin: 0;
        margin-bottom: 40px;
    }
    .page .protocols {
        margin-left: 0;
        margin-right: 0;
    }
    .page-title {
        font-size: 24px;
    }
    .page-innovative {
        height: 250px;
    }
    .page .innovatives {
        display: flex;
        flex-direction: column;
    }
    .page .certificate-list {
        margin-top: -40px;
    }
}
</style>
