<template>
    <div class="new">
        <div class="new-wrap" :class="{ oposit: !oposit }">
            <div class="new-image">
                <img :src="info.image" alt="" />
            </div>
            <div class="new-text">
                <div class="new-title">{{ info.title }}</div>
                <div class="new-description" v-if="info.description" v-html="info.description"></div>
                <div class="new-button">
                    <main-button :label="$t('allNews')" @change-event="redirect(info.slug)" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "new",
    props: {
        info: {
            type: Object,
            default: () => {},
        },
        oposit: {
            type: Boolean,
            default: () => false,
        },
    },
    methods: {
        redirect(slug) {
            this.$router
                .push({
                    name: "page",
                    params: {
                        slug: slug,
                    },
                    query: {
                        type: "new",
                        title: this.$route.query.title,
                    },
                })
                .catch(() => {});
        },
    },
};
</script>

<style lang="scss">
.new {
    &-image {
        width: 55%;
        height: 700px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &-wrap {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        &.oposit {
            .new-text {
                order: 1;

                .new-description {
                    margin-right: -25px;
                    margin-left: 0;
                    z-index: 2;
                }
            }

            .new-image {
                order: 2;
                z-index: -1;
            }
        }
    }

    &-text {
        width: 40%;
        margin-left: 30px;
    }

    &-title {
        font-weight: normal;
        font-size: 40px;
        line-height: 49px;
        text-transform: uppercase;
        color: #000000;
        margin-bottom: 35px;
    }

    &-description {
        font-weight: normal;
        font-size: 18px;
        line-height: 24px;
        color: #4f4f4f;
        background: #f2f2f2;
        padding: 48px 58px;
        margin-bottom: 50px;
        margin-left: -50px;
        z-index: 2;
    }

    &-button {
        height: 43px;
        max-width: 250px;
    }
}

@media screen and (max-width: 780px) {
    .new-description {
        padding: 20px 30px;
        font-size: 16px;
        margin-bottom: 15px;
        margin-left: 0;
    }
    .new-wrap {
        flex-direction: column;

        &.oposit {
            .new-text {
                order: 2;

                .new-description {
                    margin-right: 0;
                    margin-left: 0;
                    z-index: 2;
                }
            }

            .new-image {
                order: 1;
                z-index: -1;
            }
        }
    }
    .new-image {
        width: 100%;
        height: 400px;
    }
    .new-text {
        margin-top: 15px;
        width: 100%;
        margin-left: 10px;
    }
    .new-title {
        margin-bottom: 15px;
        font-size: 24px;
        text-align: center;
    }
    .new-button {
        margin: auto;
    }
}
</style>
